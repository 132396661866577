// footer
.footer {
	background: $forest-green;
	padding: 6vh 0 0 0;
	background-image: url("/images/footer-bottom.svg");
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: 100%;
	.footer-main {
		@include media-breakpoint-down(lg) {
			padding-bottom: 9vh;
			.container-holder {
				flex-flow: column;
				.one,
				.two {
					max-width: 80% !important;
					margin: auto;
				}
				.one {
					margin-bottom: 6vh;
				}
			}
		}
		.one {
			max-width: 35% !important;
			flex-basis: 35% !important;
		}
		.two {
			max-width: 65% !important;
			flex-basis: 65% !important;
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-weight: 400;
				color: $white;
			}
			p {
				color: $white;
			}
			a {
				font-weight: 700;
				color: $bright-orange;
				&:hover {
					color: $light-orange;
				}
			}
		}
		padding-bottom: 22vh;
	}
	.footer-socket {
		padding: 10px;
		.list {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 1.5rem;
			a {
				color: $forest-green;
				text-decoration: none;
font-weight: 600;
				&:hover {
					text-decoration: underline;
					text-underline-offset: 5px;
				}
			}
		}
	}
}
