// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $body-bg;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
		.logo {
			width: 130px;
			transition: 0.35s ease;
		}
		div.site-switcher {
			display: none;
			&.mobile {
				display: flex;
				position: relative;
				top: unset;
				right: unset;
				margin-left: 0.65rem;
				opacity: 1;
				transition: 0.35s;
				transition-delay: 0.35s;
				a::after {
					display: none;
				}
			}
		}
	}
	&.sticky-mobile {
		@include media-breakpoint-down(lg) {
			.logo {
				order: 0;
				margin: 0 0 0 15px;
				max-width: 100px;
			}
		}
	}

	.container,
	.container-fluid {
		@include media-breakpoint-up(xl) {
			padding: 0 2rem;
		}
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding: 15px 5px;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.news-container {
			background: $white;
			position: absolute;
			top: 0;
			left: 0;
			position: relative;
			overflow: hidden;
			width: 100% !important;
			@include media-breakpoint-down(lg) {
				.column {
					padding: unset;
				}
			}
			div.news-ticker {
				display: flex;
				flex-flow: row nowrap;
				&:hover ul {
					animation-play-state: paused;
				}
				ul {
					height: 40px;
					animation: scroll 25s linear infinite;
					min-width: 100vw;
					transition: 1s ease-out;
					float: left;
					display: flex !important;
					flex-flow: row nowrap !important;
					align-items: center;
					gap: 5rem;
					min-width: fit-content;
					max-width: none !important;
					padding-left: 5rem;
					a {
						text-decoration: none;
						&:hover {
							text-decoration: underline;
							text-underline-offset: 7px;
						}
					}
					li {
						min-width: fit-content;
						display: flex;
						align-items: center;
						font-size: 13px;
						font-weight: 500;
						b {
							font-weight: 700;
						}

						&::after {
							content: "\f005 \f005 \f005 \f005 \f005";
							font-family: $font-awesome;
							color: $light-green;
							font-size: 12px;
							font-weight: 600;
							margin-left: 3rem;
						}
					}

					@keyframes scroll {
						0% {
							transform: translateX(0%);
						}

						100% {
							transform: translateX(-100%);
						}
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			color: #0c6338;
			i {
				transition: 0.5s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: scaleX(-1);
					transition: 0.5s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 285px;
		margin: 30px 10px 30px 0;
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			order: 2;
			margin: 15px auto;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;
			flex-basis: 100%;

			.navbar-nav {
				padding: 1rem;
				.nav-item {
					border-bottom: 1px solid $primary;

					.nav-link {
						font-family: $font-family-primary;
						color: $black;
						font-size: 16px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $orange !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			margin-left: auto;
			margin-right: 1.5rem;
			.navbar-nav {
				margin-left: auto;
				.nav-item {
					.nav-link {
						font-weight: 700;
						color: $dark-navy;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: underline;
							text-underline-offset: 7px;
							color: $primary;
						}
					}
					.dropdown-menu.show {
						background: $body-bg;
						border: 0;
						box-shadow: none;
						padding: 1rem 2.5rem;
						max-width: unset;
						width: max-content;
						&::before,
						&::after {
							display: none;
						}
						.nav-item {
							text-align: center;
							.nav-link {
								text-decoration: none;
							}
							&:hover {
								text-decoration: underline;
								text-underline-offset: 5px;
							}
						}
					}
				}
			}
		}
	}

	// book-btn
	div.book-button {
		a {
			@extend .btn, .btn-primary;
			background-color: $lavender;
		}
		@include media-breakpoint-down(md) {
			margin-left: auto;
		}
	}

	// site-switcher
	div.site-switcher {
		position: absolute;
		top: 15px;
		right: 20px;
		img {
			transition: 0.35s ease;
		}
		&.mobile {
			display: none;
			opacity: 0;
		}
		@include media-breakpoint-down(md) {
			display: none;
			&.mobile {
				display: flex;
				position: relative;
				top: unset;
				right: unset;
				margin-left: 0.5rem;
				opacity: 1;
				a::after {
					display: none;
				}
			}
		}
	}
}
