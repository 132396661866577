// btn
*:has(.btn) {
	z-index: 1;
}
.bg-light,
.bg-dark {
	.btn::after {
		background-color: $white;
	}
}

.btn {
	font-weight: 700;
	padding: 13px 22px;
	line-height: 16px;
	border-radius: 35px;
	border: none;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 0;
		height: 100%;
		background-color: rgba($primary, 0.7);
		z-index: -1;
		border-radius: 35px;
		transition: 0.35s ease;
	}
	&:hover {
		&::after {
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			transition: 0.35s ease;
		}
	}

	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
