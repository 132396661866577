// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

body
	*:has(
		.btn,
		body .owl-carousel .item .owl-caption .owl-btn,
		body
			.timeslot-item
			.timeslot-item-navigation
			.timeslot-item-navigation-btn,
		body .header div.book-button a,
		body .card-btn,
		body .btn-down,
		body .search-book .tommy-zeb-wrapper .tommy-zeb-btn
	) {
	z-index: unset !important;
	.w3-popover-body {
		z-index: 1000 !important;
	}
}

// colors
$white: #ffffff;
$light-beige: #f6f0de;
$dark-green: #006738;
$forest-green: #0c6338;
$lime-green: #8cc63f;
$red: #ce2b37;
$dark-navy: #062415;
$purple: #614864;
$yellow: #fdb713;
$blue: #2b4e90;
$lavender: #846c87;
$black: #000000;
$bright-green: #40ae49;
$teal: #71a2a7;
$light-green: #98cc68;
$light-grey: #edf0f3;
$orange: #f9a800;
$bright-orange: #fbb004;
$light-orange: #ffd27e;
$light-cream: #ffe7bb;
$black: $dark-navy;
// theme-colors
$primary: $purple;
$secondary: $forest-green;

// body
$body-bg: $light-beige;
$body-color: $dark-navy;

// links
$link-color: $primary;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");
$font-family-primary: "Istok Web", sans-serif;
$font-family-secondary: "Changa One", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 0.9375rem; // 15px

$h1-font-size: $font-size-base * 2.6667; // 40px
$h2-font-size: $font-size-base * 2.1333; // 32px
$h3-font-size: $font-size-base * 1.8667; // 28px
$h4-font-size: $font-size-base * 1.6; // 24px
$h5-font-size: $font-size-base * 1.3333; // 20px
$h6-font-size: $font-size-base * 1.0667; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $secondary;

// contextual
$light: $bright-green;
$dark: $secondary;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
