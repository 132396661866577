// mini-sab
.mini-sab {
	.container {
		// position: relative;
		// @extend .d-flex;
		// @extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: -3vh;
	}
}
