// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title {
		font-size: 96px;
		font-weight: 400;
		font-family: $font-family-secondary;
		@include media-breakpoint-down(lg) {
			font-size: 48px;
		}
	}
	.owl-subtitle {
		font-weight: 700;
		font-size: 36px;
		font-family: $font-family-primary;
		text-align: center;
		margin: auto;
		max-width: 70%;
		@include media-breakpoint-down(lg) {
			font-size: 20px;
		}
	}
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 55vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 70vh;
	}
}

section.eyecatcher {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		bottom: 1rem;
		left: 0;
		width: 100%;
		height: 6vh;
		background: url("/images/eyecatcher-bottom.svg");
		background-size: contain;
		background-repeat: repeat-x;
		z-index: 100;
	}
}
