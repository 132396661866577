// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// home-usp
.home-usp-section {
	padding: 6vh 0 0 0;
	.wysiwyg {
		ul {
			display: flex;
			width: 100%;
			justify-content: center;
			gap: 2rem;
			flex-flow: row;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				gap: 0.25rem;
				margin: auto;
				width: fit-content;
			}
			li {
				font-size: 14px;
				font-weight: 500;
				color: $forest-green;
				&::before {
					font-size: 16px;
				}
			}
		}
	}
}
// home intro section
.home-intro-section {
	padding: 6vh 0;
	position: relative;
	&::before {
		content: "";
		height: calc(100% + 12vh);
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -12vh;
		pointer-events: none;
		background-image: url("/images/home-intro-section-top-right-large.svg");
		background-position: top 0px right 0px;
		background-repeat: no-repeat;
		z-index: -1;
		@include media-breakpoint-down(lg) {
			background-position: center right;
		}
	}
	&::after {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
		background-image: url("/images/home-intro-section-top-right.png");
		background-position: top 0px right 0px;
		background-repeat: no-repeat;
		background-attachment: fixed;
		z-index: -1;
		@include media-breakpoint-down(lg) {
			background-position: center right;
			background-attachment: scroll;
		}
	}
	.title,
	.subtitle {
		max-width: 750px;
		margin: auto;
		text-align: center;
	}
	.subtitle {
		color: $dark-navy;
		@include media-breakpoint-down(lg) {
			max-width: 80%;
			margin-top: 1rem;
			font-size: 20px;
		}
	}
	.grid {
		margin-top: 6vh;
		.card {
			border-radius: 5px;
			border: none;
			.card-img-overlay {
				background: transparent;
				.card-caption {
					position: absolute;
					bottom: 0.5rem;
					left: 0;
					right: 0;
					margin: auto;
					.card-subtitle,
					.card-description {
						display: none;
					}
					.card-title {
						text-align: center;
						font-size: $h1-font-size;
						font-weight: 400;
						font-family: $font-family-secondary;
						@include media-breakpoint-down(lg) {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
	.description {
		text-align: center;
		margin-top: 5vh;
	}
}

// home surrounding section
.home-surrounding-section {
	padding: 6vh 0;
	background-image: url("/images/home-surrounding-section-bottom-left.svg");
	background-position: bottom -400px left 0px;
	background-repeat: no-repeat;
	background-attachment: fixed;
	@include media-breakpoint-down(lg) {
		background-attachment: scroll;
		background-position: center;
	}
	.title,
	.subtitle {
		max-width: 750px;
		margin: auto;
		text-align: center;
	}

	.subtitle {
		color: $dark-navy;
	}

	.grid:not(.blocks) {
		margin-top: 11vh;
		@include media-breakpoint-down(lg) {
			.grid-items {
				flex-flow: column;
				margin-top: 10vh;
			}
		}
		.item {
			display: none;
			flex-basis: 16% !important;
			max-width: 16% !important;
			padding: 0 !important;
			@include media-breakpoint-down(lg) {
				flex-basis: 100% !important;
				max-width: 100% !important;
				transform: translate(-15%, 0);
				margin-top: -10vh;
			}
			&:nth-child(-n + 6) {
				display: block;
			}
			.card {
				background: $forest-green;
				width: calc(100% + 1.5rem);
				aspect-ratio: 1/1;
				border-radius: 50%;
				transition: 1s ease;
				overflow: visible;
				align-items: center;
				justify-content: center;
				border: none;
				@include media-breakpoint-down(lg) {
					max-width: 50%;
					margin: auto;
				}
				&:hover {
					transform: translateY(3.5%) !important;
					transition: 1s ease;
					.card-image i {
						font-weight: 600;
						transform: rotate(360deg);
						transition: 0.35s ease;
					}
				}
				.card-image {
					display: flex;
					align-items: center;
					justify-content: center;
					aspect-ratio: 1/1;
					border-radius: 50%;
					background: $yellow;
					width: 78px;
					position: absolute !important;
					left: 50%;
					top: -39px;
					transform: translate(-50%, 0);
					i {
						color: $white;
						font-size: 32px;
					}
					@media (max-width: 360px) {
						width: 40px;
						top: -20px;
						i {
							font-size: 22px;
						}
					}
				}
				.card-caption {
					margin: auto;
					overflow: hidden;
					display: flex;
					flex-flow: column;
					align-items: center;
					justify-content: center;

					.card-title,
					.card-subtitle {
						color: $white;
						text-align: center;
					}
					.card-title {
						font-size: 20px;
						@include media-breakpoint-down(sm) {
							font-size: 16px;
						}
					}
					.card-subtitle {
						font-size: 15px;
						font-family: $font-family-primary;
						font-weight: 500;
						max-width: 80%;
						@include media-breakpoint-down(lg) {
							max-width: 95%;
						}
					}
					.card-description,
					.card-buttons {
						display: none;
					}
				}
			}
			&:nth-child(2) .card {
				background: $bright-green;
				transform: translateY(10%);
				@include media-breakpoint-down(lg) {
					transform: translate(60%, 0);
				}
			}
			&:nth-child(3) .card {
				background: $forest-green;
				transform: translateY(10%);
			}
			&:nth-child(4) .card {
				background: $light-green;
				transform: translateY(10%);
				@include media-breakpoint-down(lg) {
					transform: translate(60%, 0);
				}
			}
			&:nth-child(5) .card {
				background: $bright-green;
				transform: translateY(-5%);
			}
			&:nth-child(6) .card {
				background: $forest-green;
				transform: translateY(10%);
				@include media-breakpoint-down(lg) {
					transform: translate(60%, 0);
				}
			}
		}
	}

	.grid.blocks {
		margin-top: 12vh;
		@include media-breakpoint-down(lg) {
			padding: 0 1rem;
		}
		.item {
			.card {
				border: 0;

				background: none;
				.card-image {
					border-radius: 6px;
				}
				.card-body {
					padding: 0.75rem 0;
					.card-title {
						font-weight: 400;
						font-size: $h4-font-size;
						color: $purple;
						margin-bottom: 0;
					}
					.card-description {
						max-width: 70%;
					}
				}
			}
		}
	}
}
// content eyecacther
section.content-eyecatcher {
	margin: 0 auto 6vh auto;
	padding-bottom: 6vh;
	position: relative;
	overflow: hidden;
	@include media-breakpoint-down(lg) {
		padding-bottom: 12vh;
	}
	.owl-carousel {
		.owl-nav,
		.owl-dots {
			display: none;
		}
		.owl-stage-outer {
			overflow: visible;
		}
		.item {
			padding: 0;
		}
		.owl-item {
			min-height: 85vh;
			@include media-breakpoint-down(lg) {
				min-height: 65vh;
			}
			.owl-caption-holder {
				height: 100%;
				position: relative;
				&::after {
					content: "";
					position: absolute;
					bottom: 1rem;
					left: 0;
					width: 100%;
					height: 8vh;
					background: url("/images/eyecatcher-bottom.svg");
					background-size: contain;
					background-repeat: repeat-x;
					z-index: 100;
					transform: scaleX(-1);
				}
				.owl-caption {
					display: block;
					background: $lavender;
					width: 20vw;
					z-index: 200;
					aspect-ratio: 1/1;
					position: absolute;
					bottom: -3vh;
					left: 10vw;
					padding: 2rem;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-flow: column;
					border-radius: 50%;
					@include media-breakpoint-down(lg) {
						min-width: 80%;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						bottom: -12vh;
						padding: 1rem;
					}
					.owl-title {
						font-size: $h5-font-size;
						font-weight: 400;
					}
					.owl-subtitle {
						@extend.subtitle;
					}
					.owl-description {
						font-weight: 500;
					}
				}
			}
		}
	}
}
// home outro
.home-outro-section {
	padding: 6vh 0;
	background-image: url("/images/home-outro-section-bottom-right.svg");
	background-position: bottom -420px right 0px;
	background-repeat: no-repeat;
	background-attachment: scroll;
	@include media-breakpoint-down(lg) {
		padding: 1rem 0 9vh 0;
		background-position: bottom -520px right 0px;
		.one,
		.two {
			max-width: 90% !important;
			margin: auto;
		}
	}
	.one,
	.two {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $purple;
			font-weight: 400;
		}
		a {
			color: #000;
			font-weight: 700;
			text-decoration: underline;
			&:hover {
				color: $purple;
			}
		}
		.vocabulary-term-list {
			display: flex;
			gap: 0.65rem;
			li {
				.list-item-title {
					display: none;
				}
				i {
					font-size: 28px;
					color: $teal;
					transition: 0.35s ease;
				}
				&:hover i {
					color: $dark-navy;
					transition: 0.35s ease;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		.subtitle * {
			@extend.subtitle;
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				font-weight: 600;
			}

			.icon {
				margin-right: 5px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
