// custom-list
.custom-list {
	padding: 0;
	list-style: none;
	gap: 0.35rem;
	display: flex;
	flex-flow: column;
	li {
		position: relative;
		padding-left: 1.35rem;
		&::before {
			content: "\f00c";
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;
	gap: 0.35rem;
	display: flex;
	flex-flow: column;
	li {
		position: relative;
		padding-left: 1.35rem;

		&::before {
			content: "\f058";
			font-family: $font-awesome;
			color: $primary;
			font-weight: 600;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
		}
	}
}
